@import "styles/variables.scss";

.inputWrapper {
  width: 60%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 16px;

  @media (max-width: $mobile) {
    width: auto;
    flex-grow: 1;
    overflow: hidden;
  }
}

.price {
  color: #fff;

  @media (max-width: $mobile) {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }
}

.input {
  width: 65%;
  height: 50px;
  background-color: transparent;
  border: none;
  outline: none;

  color: #fff;
  text-align: right;
  font-size: 18px;

  @media (max-width: $mobile) {
    width: 60%;
  }
}

.wrapper {
  background-color: #111342;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
}

.tokenWrapper {
  padding: 16px;
  width: 40%;

  @media (max-width: $mobile) {
    width: auto;
    padding-right: 0;
    white-space: nowrap;
  }
}

.poolToken {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.avatarWrapper {
  display: flex;
  align-items: center;
}

.avatarWrapper .poolAbbr {
  margin-left: 16px;
  font-size: 14px;
  font-weight: 600;

  @media (max-width: $mobile) {
    display: none;
  }
}

.maxButton {
  position: absolute;
  left: 16px;
  bottom: 20px;
}
