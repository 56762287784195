// Responsive
$mobile: 767px;
$tablet: 1024px;
$gutter-mobile: 20px;
$gutter-tablet: 30px;

// Colors
$dark: #0c0d30;
$white: #ffffff;
$green: #5cde2e;
$red: #eb5757;
$orange: #ffb800;
