@import "styles/variables.scss";

.modal {
  @media (max-width: $mobile) {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }
}

:global(.ant-modal-wrap) {
  @media (max-width: $mobile) {
    background-color: $dark;
  }
}

.modal :global(.ant-modal-content) {
  background-color: #0c0d30;
  color: #fff;
  border-radius: 16px;

  position: relative;
  background-clip: padding-box;
  border: 1px solid transparent;

  @media (max-width: $mobile) {
    height: 100vh;
    width: 100%;
    margin: 0;
    top: 0;
    border-radius: 0;
  }
}

.modal :global(.ant-modal-content):before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
  background: linear-gradient(
    132deg,
    #ffb800 14.21%,
    #00ffdd 51.62%,
    #7000ff 100%
  );

  @media (max-width: $mobile) {
    background: none;
  }
}

.modal :global(.ant-modal-close) {
  text-indent: -9999px;
  top: 28px;
  right: 20px;

  &::before,
  &:after {
    content: " ";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    width: 17px;
    height: 3px;
    margin: auto;

    background-color: $white;
    border-radius: 3px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: $mobile) {
    top: 15px;
    right: 0;
  }
}

.modal :global(.ant-modal-close-x) {
  color: #fff;
}

.modal :global(.ant-modal-body) {
  padding: 40px;

  @media (max-width: $mobile) {
    padding: 25px $gutter-mobile;
  }
}

.modal :global(.ant-modal-body .ant-col > .ant-btn) {
  width: 100%;
  height: 52px;
}
