.avatar {
  border: 2px solid #fff;
  z-index: 1;
  background: #fff;
}

.avatarToken {
  margin-left: -12px;
  z-index: 0;
}
