@import "styles/variables";

.modal {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 90%;
  }
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  line-height: 36px;
}

.description {
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  margin-top: 18px;
}

.button:global(.ant-btn) {
  height: 52px;
}

.successWrapper {
  margin: 40px auto;
}

.codeWrapper {
  display: none;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
    margin-bottom: 36px;
    padding: 16px;
    border-radius: 8px;
    background-color: #0c0d30;

    position: relative;
    background-clip: padding-box;
    border: 1px solid transparent;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -1px;
      border-radius: inherit;
      background: linear-gradient(
        132deg,
        #ffb800 14.21%,
        #00ffdd 51.62%,
        #7000ff 100%
      );
    }
  }
}

.hint {
  display: none;

  @media (max-width: $mobile) {
    display: block;
    margin-top: 16px;
    text-align: center;
  }
}

.code {
  display: flex;
  align-items: center;
}

.action__button {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
  color: #25a2e9;
  margin-left: 10px;

  @media (max-width: $mobile) {
    font-size: 12px;
  }

  &:hover {
    color: #3993c7;
  }
  span {
    margin-left: 8px;
  }
}
