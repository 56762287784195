@import "styles/variables.scss";

.header:global(.ant-layout-header) {
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 16px;
  padding: 0 40px;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    row-gap: 10px;
    padding-right: $gutter-tablet;
    padding-left: $gutter-tablet;
  }

  @media (max-width: $mobile) {
    display: block;
    height: auto;
    margin-bottom: 10px;

    padding-right: $gutter-mobile;
    padding-left: $gutter-mobile;

    & > svg {
      width: 106px;
      height: 28px;
      margin-bottom: 20px;
    }
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;

  @media (max-width: $mobile) {
    flex-direction: column;
  }
}

.walletWrapper {
  display: flex;
  margin-left: 10px;
  column-gap: 10px;
  row-gap: 10px;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0;
  }
}

.accountWrapper,
.statusWrapper {
  @media (max-width: $mobile) {
    width: 100%;
  }
}
