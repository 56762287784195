.button:global(.ant-btn) {
  background-color: #7000ff;
  border-radius: 20px;
  border: none;
  height: 40px;

  color: #fff;
  font-size: 16px;
  font-weight: 600;

  padding: 8px 21px;

  width: 100%;
}

.button :global(.ant-avatar) {
  margin-right: 10px;
}

.button :global(.anticon) {
  font-size: 10px;
  vertical-align: baseline;
}

.button:global(.ant-btn[disabled]),
.button:global(.ant-btn[disabled]:hover) {
  background-color: #2e3071;
  color: #fff;
  pointer-events: none;
}

.button:global(.ant-btn-lg) {
  height: 65px;
  font-size: 18px;
  padding: 8px 36px;
}

.additionalText {
  font-size: 14px;
  opacity: 0.8;
}

/* Button with link */
:global(a).button:global(.ant-btn) {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
}
