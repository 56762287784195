.purefi-widget {
  width: 250px;
}

.purefi-status__description {
  width: 100% !important;
}

.purefi-modal__title {
  color: var(--purefi_white);
}

.purefi-button {
  width: 100%;
  height: 40px !important;
  max-height: 40px !important;
  font-size: 16px;

  &.purefi-button-icon {
    border-radius: 20px;
  }
}

.purefi-modal,
.purefi-notification-notice,
.purefi-button.purefi-button_primary {
  border: 1px solid var(--purefi_button-secondary-color);
}
