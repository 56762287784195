@import "styles/variables";

.modal {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 90%;
  }
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  line-height: 36px;
}

.description {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 18px;
}

.button:global(.ant-btn) {
  height: 52px;
}

.successWrapper {
  margin: 40px auto;
}
