@import "styles/variables.scss";

.button {
  display: flex;
  align-items: center;
  background-color: #313a8b;
  border-radius: 20px;
  color: #fff;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;

  @media (max-width: $mobile) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &.buttonBlink {
    animation-duration: 0.65s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-name: blink;
  }
}

.avatar {
  margin: 0 4px;
  min-width: 32px;
}

.left {
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  display: inline-block;
  padding: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.right {
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  display: inline-block;
  background-color: #0c0d30;
  border-radius: 20px;
  padding: 8px 21px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: $mobile) {
    margin-left: auto;
    min-width: 50%;
  }
}

@keyframes blink {
  to {
    background-color: #983eff;
  }
}
