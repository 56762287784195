@import "styles/variables.scss";

.layout {
  &:global(.ant-layout),
  :global(.ant-layout-header) {
    background: none;
  }
}

.layout {
  max-width: 1440px;
  margin: 0 auto;
}

.content {
  margin: 40px auto;
  padding: 0 40px;
  max-width: 1020px;
  width: 100%;

  @media (max-width: $tablet) {
    padding-right: $gutter-tablet;
    padding-left: $gutter-tablet;
  }

  @media (max-width: $mobile) {
    padding-right: $gutter-mobile;
    padding-left: $gutter-mobile;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile;
  }
}

.alert {
  margin-bottom: 20px;
}
