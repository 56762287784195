@import "styles/variables.scss";

.title {
  font-weight: 600;
  font-size: 24px;
  color: $white;
  line-height: 36px;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 16px;
}

.safe,
.low {
  color: $green;
}

.risky,
.high {
  color: $red;
}

.warning,
.medium {
  color: $orange;
}

.riskLevel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 50px;

  .legend {
    &:first-child {
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }

  .scale {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 10px;
    background-image: linear-gradient(to right, $green, #adcf43, $red);

    .pointer {
      position: absolute;
      top: 5px;
    }

    .relative {
      position: relative;
    }

    .hint {
      color: white;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      position: absolute;
      left: -32px;
      width: 80px;
    }

    .arrowUp {
      width: 0px;
      height: 0px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 10px solid $white;
    }
  }
}

.statusWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  img {
    padding-right: 10px;
  }
}

.status {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  width: 100%;
}

.connections {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;

  .header {
    margin-bottom: 18px;
    color: #fff;
  }

  .item {
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
    padding-top: 7px;
    border-bottom: 1px solid #39425e;
    height: 42px;

    &:first-child {
      padding-top: 7;
    }

    &:last-child {
      border-bottom: 0;
    }

    .left {
      .connection {
        margin-right: 16px;
      }
    }

    .right {
    }
  }
}

.wrapper {
  padding-right: 14px;
}

.toggleButton {
  color: #00ffd1;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
}

.emptyList {
  font-weight: 400;
}

.collapseItem {
  border-radius: 10px;
  &:hover {
    background-color: #0709259e;
  }
  margin-bottom: 10px;
  .expandIcon {
    color: #fff;
    z-index: 0;
  }
  .ant-collapse-header-text {
    position: relative;
    user-select: none;
    display: block;
    width: calc(100% + 30px);
  }
}

.ant-collapse-item-active.collapseItem {
  background-color: #0709259e !important;
}
