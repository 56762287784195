@import "styles/variables.scss";

.description {
  margin-bottom: 30px;
}

.title {
  font-size: 24px;
  line-height: 36px;
  background-image: linear-gradient(45deg, #caa0ff, #79ffe7);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  font-weight: 600;

  margin-bottom: 34px;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.warning {
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  background-image: linear-gradient(45deg, #ffde89, #caa0ff, #79ffe7);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  margin-bottom: 34px;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.step {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

.links {
  display: flex;
  align-items: center;
  justify-content: space-between;

  a:last-child {
    margin-left: 16px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    margin-top: 20px;

    a {
      width: 100%;
    }

    a:last-child {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
