@import "styles/variables.scss";

.button:global(.ant-btn) {
  background-color: #0060b8;

  @media (max-width: $mobile) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.dropdown:global(.ant-dropdown) {
  padding: 16px 8px;
  border-radius: 20px;
  background-color: #141823;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px 0, rgba(0, 0, 0, 0.25) 0 1px 2px 0;
}

.dropdown :global(.ant-dropdown-menu) {
  background-color: #141823;
}

.menuItem {
  color: #6c86ad;
  font-size: 16px;
  font-weight: 600;
}

.menuItem:hover {
  color: #fff;
  background: none;
}

.activeMenu {
  color: #fff;
}
